<template>
  <!--begin::Service Listing-->
  <div class="service-template">
    <PageHeaderCount
      moduleType="service"
      :dataLoading.sync="dataLoading"
      :statusList.sync="statusList"
      allkey="all_services"
      countkey="service_status_count"
    ></PageHeaderCount>
    <ListingTemplate
      :customClass="'service-listing'"
      v-if="getPermission('service:view')"
    >
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template v-if="selectedRows.length > 0">
              <v-col cols="4" class="pt-0">
                <h1
                  class="form-title custom-nowrap-ellipsis margin-auto selected-rows-text"
                >
                  {{ totalSelected }} Selected
                </h1>
              </v-col>
              <v-col cols="8" class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('service:update')">
                  <v-menu
                    content-class="custom-menu-list"
                    bottom
                    offset-y
                    :close-on-click="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="dataLoading"
                        class="mx-2 custom-grey-border custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark left>mdi-chevron-down</v-icon>Bulk Action
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(item, index) in bulkActions">
                        <v-list-item
                          link
                          v-on:click="productBulkAction(item.status)"
                          :key="index"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                            <v-icon class="icon-default-blue">{{
                              item.icon
                            }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >{{ item.title }}</v-list-item-title
                          >
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="red lighten-1 white--text"
                  v-on:click="clearSelections"
                >
                  <v-icon dark left>mdi-close</v-icon>Clear Selections
                </v-btn>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="4" class="pt-0">
                <h1 class="form-title d-flex margin-auto">
                  <v-select
                    :disabled="dataLoading"
                    :items="statusList"
                    v-model="status"
                    hide-details
                    item-color="cyan"
                    class="pt-0 mt-0 listing-status main-listing-status-filter"
                    item-text="text"
                    item-value="value"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="item.description"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          :color="item.color"
                          :text-color="item.textcolor"
                        >
                          <template v-if="item.value == 'all'">{{
                            item.all_services
                          }}</template>
                          <template v-else>{{
                            item.service_status_count
                          }}</template>
                        </v-chip>
                      </v-list-item-action>
                    </template>
                  </v-select>
                </h1>
              </v-col>
              <v-col cols="8" class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('service:create')">
                  <v-btn
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button"
                    :to="getDefaultRoute('service.create')"
                    color="cyan white--text"
                  >
                    <v-icon dark left>mdi-plus</v-icon> Create
                  </v-btn>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                  v-on:click="searchDialog = true"
                >
                  <v-icon dark>mdi-filter</v-icon>
                </v-btn>
                <v-btn
                  v-if="!isEngineerChannel()"
                  v-on:click="
                    $router.push(
                      getDefaultRoute('profile.setting', {
                        query: {
                          tab: 'service'
                        }
                      })
                    )
                  "
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                >
                  <v-icon dark>mdi-cog</v-icon>
                </v-btn>
                <v-menu content-class="custom-menu-list" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading || exportLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                      :loading="exportLoading"
                    >
                      <v-icon dark>mdi-menu</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template v-for="(item, index) in moreActions">
                      <v-list-item
                        :key="index"
                        link
                        :class="{ 'line-active': item.sort }"
                        v-on:click="moreAction(item.action, item.sort)"
                      >
                        <v-list-item-title>
                          <v-icon v-if="item.icon" small left color="cyan">{{
                            item.icon
                          }}</v-icon>
                          {{ item.title }}
                          <template v-if="item.sort">
                            <v-icon
                              v-if="item.sort == 'asc'"
                              small
                              right
                              color="cyan"
                              >mdi-sort-ascending</v-icon
                            >
                            <v-icon
                              v-if="item.sort == 'desc'"
                              small
                              right
                              color="cyan"
                              >mdi-sort-descending</v-icon
                            >
                          </template>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
                <v-menu
                  max-height="400"
                  max-width="250"
                  offset-y
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="cols.fixed || dataLoading"
                          color="cyan"
                          hide-details
                          class="pt-2 mt-0"
                        ></v-checkbox>
                        <v-icon
                          class="draggable-drag-icon"
                          small
                          right
                          color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>
                <PageTips module="service"></PageTips>
              </v-col>
            </template>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled"
          pageModule="Service"
          :basicSearchFields="[
            'name',
            'barcode',
            'reference',
            'sku',
            'upc',
            'ean',
            'isbn',
            'part_number',
            'hsn_code'
          ]"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria>
        <!-- <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 60vh; position: relative"
        > -->
        <ListingTable
          :columnCount.sync="defaultColDefs.length"
          :dataLoading.sync="dataLoading"
          :rowData.sync="rowData"
        >
          <template v-slot:thead>
            <thead>
              <tr>
                <template v-for="cols in defaultColDefs">
                  <th
                    v-if="cols.visible"
                    :key="cols.id"
                    class="simple-table-th"
                    :class="{ 'checkbox-width-limit': cols.checkbox }"
                    :style="{ 'max-width': cols.width }"
                  >
                    <template v-if="cols.checkbox">
                      <v-checkbox
                        dense
                        :disabled="dataLoading"
                        v-model="selectedAll"
                        color="cyan"
                        class="hide-margin"
                        hide-details
                      ></v-checkbox>
                    </template>
                    <template v-else>
                      <div
                        v-if="cols.sortable"
                        class="simple-table-text sortable"
                        v-on:click="
                          !dataLoading
                            ? updateSorting({
                                field: cols.field,
                                sort: cols.sort
                              })
                            : 'javascript:void(0)'
                        "
                      >
                        {{ cols.headerName }}
                      </div>
                      <div v-else class="simple-table-text">
                        {{ cols.headerName }}
                      </div>
                      <template v-if="cols.sort">
                        <v-icon
                          class="simple-table-sort"
                          v-if="cols.sort == 'asc'"
                          small
                          right
                          color="cyan"
                          >mdi-sort-ascending</v-icon
                        >
                        <v-icon
                          class="simple-table-sort"
                          v-if="cols.sort == 'desc'"
                          small
                          right
                          color="cyan"
                          >mdi-sort-descending</v-icon
                        >
                      </template>
                    </template>
                  </th>
                </template>
              </tr>
            </thead>
          </template>
          <template v-slot:tbody>
            <tbody class="custom-border-bottom">
              <template v-if="rowData.length > 0">
                <tr
                  v-for="(data, index) in rowData"
                  :key="index"
                  link
                  :class="{ 'table-in-active-row': !data.activated }"
                >
                  <template v-for="cols in defaultColDefs">
                    <td
                      v-if="cols.visible"
                      :key="cols.id"
                      :class="{
                        'simple-table-td': !cols.checkbox,
                        'checkbox-width-limit': cols.checkbox
                      }"
                      :style="{ 'max-width': cols.width }"
                      v-on:click="
                        !cols.checkbox ? rowClicked(data) : 'javascript:void(0)'
                      "
                    >
                      <template v-if="cols.checkbox">
                        <v-checkbox
                          dense
                          v-model="selectedRows"
                          v-bind:value="data.id"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else-if="cols.field == 'barcode'">
                        <Barcode :barcode="data.barcode"></Barcode>
                      </template>
                      <template v-else-if="cols.field == 'service_info'">
                        <p class="m-0 custom-nowrap-ellipsis">
                          <b>Name: </b> {{ data.name || " - " }}
                        </p>
                        <p
                          class="m-0 custom-nowrap-ellipsis"
                          v-if="data.reference"
                        >
                          <b>Reference: </b> {{ data.reference || " - " }}
                        </p>
                      </template>
                      <template v-else-if="cols.field == 'price'">
                        <p
                          class="m-0 custom-nowrap-ellipsis"
                          v-if="data.charges"
                        >
                          <b>Charges: </b> {{ data.charges || " - " }}
                        </p>
                      </template>
                      <template v-else-if="cols.field == 'added_at'">
                        <TableActivity
                          v-if="!lodash.isEmpty(data.added_by)"
                          :data.sync="data"
                        >
                          <template v-slot:display_name>
                            {{ data.added_by.display_name }}
                          </template>
                          <template v-slot:date_time>
                            {{ data.created_at }}
                          </template>
                          <template v-slot:format_date_time>
                            {{ formatDateTime(data.added_at) }}
                          </template>
                        </TableActivity>
                      </template>
                      <template v-else-if="cols.field == 'updated_at'">
                        <TableActivity
                          v-if="!lodash.isEmpty(data.updated_by)"
                          :data.sync="data"
                        >
                          <template v-slot:display_name>
                            {{ data.updated_by.display_name }}
                          </template>
                          <template v-slot:date_time>
                            {{ data.modified_at }}
                          </template>
                          <template v-slot:format_date_time>
                            {{ formatDateTime(data.updated_at) }}
                          </template>
                        </TableActivity>
                      </template>
                      <template v-else>
                        <div v-html="printRowData(cols, data)"></div>
                      </template>
                    </td>
                  </template>
                </tr>
              </template>
              <tr v-else-if="!dataLoading">
                <td :colspan="defaultColDefs.length" class="py-4">
                  <p
                    class="m-0 row-not-found text-center font-weight-500 font-size-16"
                  >
                    <img
                      :src="$assetURL('media/error/empty.png')"
                      class="row-not-found-image"
                    />
                    Uhh... There are no service at the moment.
                  </p>
                </td>
              </tr>
            </tbody>
          </template>
        </ListingTable>
        <!-- </perfect-scrollbar> -->
        <ListingFooter
          :dataLoading.sync="dataLoading"
          :showingFrom.sync="showingFrom"
          :showingTo.sync="showingTo"
          :totalRows.sync="totalRows"
          :currentPage.sync="currentPage"
          :totalPages.sync="totalPages"
        ></ListingFooter>
        <Dialog :commonDialog="searchDialog">
          <template v-slot:title>Filter Service</template>
          <template v-slot:body>
            <!-- <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="max-height: 60vh; position: relative"
            > -->
            <v-container fluid class="pt-0 custom-search-filter">
              <v-row>
                <template v-for="(field, index) in searchableArray">
                  <v-col md="6" :key="index" v-if="field != 'dates'">
                    <v-text-field
                      v-model.trim="listingSearch[field]"
                      dense
                      filled
                      hide-details
                      :label="getFilterLabel(field, 'Service')"
                      solo
                      flat
                      clearable
                      @keydown.enter="filterRows"
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                </template>
              </v-row>
            </v-container>
            <!-- </perfect-scrollbar> -->
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="filterRows"
            >
              Filter
            </v-btn>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="searchDialog = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog>
        <template v-if="getPermission('service:create')">
          <Dialog :commonDialog="importDialog">
            <template v-slot:title>Import Service</template>
            <template v-slot:body>
              <!-- <perfect-scrollbar
                :options="{ suppressScrollX: true }"
                class="scroll custom-box-top-inner-shadow"
                style="max-height: 60vh; position: relative"
              > -->
              <v-form
                ref="productImport"
                v-model="valid_import"
                lazy-validation
                v-on:submit.stop.prevent="importRows()"
              >
                <v-container fluid class="pt-0 custom-search-filter">
                  <v-btn
                    v-on:click="downloadSample()"
                    :disabled="dataLoading || loading_imported"
                    class="mx-2 custom-grey-border custom-bold-button"
                    color="cyan white--text"
                  >
                    Download Sample
                  </v-btn>
                  <v-file-input
                    :disabled="loading_imported"
                    :rules="[
                      value =>
                        !value ||
                        value.size < 5000000 ||
                        'File size should be less than 5 MB!'
                    ]"
                    v-on:change="handleFileUpload"
                    placeholder="Select Excel File"
                    color="cyan"
                    show-size
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  ></v-file-input>

                  <div class="import-success-message">
                    <ul>
                      <li
                        v-for="(message, index) in success_message"
                        :key="index"
                      >
                        {{ message }}
                      </li>
                    </ul>
                  </div>
                </v-container>
              </v-form>
              <!-- </perfect-scrollbar> -->
            </template>
            <template v-slot:action>
              <v-btn
                v-if="!excel_imported"
                :disabled="dataLoading || !valid_import || loading_imported"
                class="mx-2 custom-grey-border custom-bold-button"
                color="cyan white--text"
                v-on:click="importRows()"
              >
                Import
              </v-btn>
              <v-btn
                :disabled="loading_imported"
                class="mx-2 custom-grey-border custom-bold-button"
                v-on:click="importDialog = false"
              >
                Close
              </v-btn>
            </template>
          </Dialog>
        </template>
      </template>
    </ListingTemplate>
  </div>
  <!--end::Service Listing-->
</template>

<script>
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import ServiceMixin from "@/core/lib/service/service.mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { DOWNLOAD, POST, PATCH } from "@/core/services/store/request.module";
import { saveAs } from "file-saver";
import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
import JwtService from "@/core/services/jwt.service";

export default {
  mixins: [CommonMixin, ListingMixin, ServiceMixin],
  name: "service-list",
  data() {
    return {
      exportLoading: false,
      pageModule: "service-listing",
      routeAPI: "service",
      routeName: "service",
      routeDetailName: "service.detail",
      status: "all",
      file: "",
      importDialog: false,
      excel_imported: false,
      valid_import: true,
      loading_imported: false,
      success_message: [],
      statusList: [],
      allowedSearchFields: [
        "barcode",
        "company_name",
        "person_display_name",
        "unit_no",
        "street_1",
        "street_2",
        "zip_code",
        "city_name",
        "state_name",
        "country_name"
      ],
      moreActions: [
        {
          title: "Import Service(s)",
          action: "import_items",
          divider: true,
          icon: "mdi-database-import"
        },
        {
          title: "Export Service(s)",
          action: "export_items",
          divider: false,
          icon: "mdi-database-export"
        },
        /*{
          title: "Export Current View",
          action: "export_current_view",
          divider: true,
          icon: "mdi-database-export"
        },*/
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh"
        }
      ],
      bulkActions: [
        {
          title: "Mark as Active",
          icon: "mdi-check-all",
          status: 1,
          action: "active"
        },
        {
          title: "Mark as In-Active",
          icon: "mdi-check-all",
          status: 0,
          action: "inactive"
        }
      ]
    };
  },
  components: {
    draggable,
    PageHeaderCount,
    PageTips,
    Barcode,
    TableActivity,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    Dialog
  },
  methods: {
    downloadSample() {
      const token = JwtService.getToken();
      const url = this.$apiURL + "service/sample-download?token=" + token;
      window.open(url, "_target");
    },
    exportService() {
      const token = JwtService.getToken();
      const url = this.$apiURL + "service/export?token=" + token;
      window.open(url, "_target");
    },
    productBulkAction(status) {
      const _this = this;
      _this.$store
        .dispatch(PATCH, {
          url: "product",
          data: {
            status,
            products: _this.selectedRows
          }
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.selectedRows = [];
          _this.getRows();
          _this.importDialog = false;
        });
    },
    moreAction(action) {
      const _this = this;
      switch (action) {
        case "import_items":
          _this.importDialog = true;
          break;
        case "export_items":
          _this.exportService();
          break;
        case "export_current_view":
          _this.exportCurrentView("service");
          break;
        case "refresh_list":
          _this.getRows();
          break;
      }
    },
    handleFileUpload(param) {
      this.file = param;
      this.excel_imported = false;
      this.success_message = [];
    },
    importRows() {
      const _this = this;
      const file = _this.file;
      if (!file) {
        alert("No file chosen");
        return;
      }
      const data = new FormData();
      _this.loading_imported = true;
      _this.excel_imported = false;
      _this.success_message = [];
      data.append("excel", file);
      _this.$store
        .dispatch(POST, {
          url: "service/import",
          data: data
        })
        .then(({ data }) => {
          _this.success_message = data;
          _this.excel_imported = true;
          _this.getRows();
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.loading_imported = false;
        });
    },
    bulkServicesExport() {
      const _this = this;
      let fileName = new Date();
      _this.exportLoading = true;
      _this.$store
        .dispatch(DOWNLOAD, {
          url: "services/export"
        })
        .then(({ data }) => {
          saveAs(data, "services-export-" + fileName.getTime() + ".xlsx");
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.exportLoading = false;
        });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Service"
      }
    ]);

    if (this.getPermission("service:create") == false) {
      let index = this.lodash.findIndex(this.moreActions, {
        action: "import_items"
      });
      if (index >= 0) {
        this.moreActions.splice(index, 1);
      }
    }
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.defaultColDefs = [
      {
        headerName: "",
        field: "id",
        sort: null,
        visible: true,
        fixed: true,
        sortable: false,
        checkbox: true,
        order: 0,
        width: "50px"
      },
      {
        headerName: "Service #",
        field: "barcode",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 1,
        width: "200px"
      },
      {
        headerName: "Service's Info",
        field: "service_info",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 3,
        width: "300px"
      },
      {
        headerName: "Price",
        field: "price",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 3,
        width: "300px"
      },
      {
        headerName: "Created Time",
        child: "created_at",
        field: "added_at",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 8,
        width: "170px"
      },
      {
        headerName: "Last Modified Time",
        child: "modified_at",
        field: "updated_at",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 9,
        width: "170px"
      }
    ];

    _this.defaultColShow = _this.defaultColDefs.map(col => col.field);

    _this.status = _this.$route.query.status || _this.status;

    _this.applyColState();

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 10;
  }
};
</script>
